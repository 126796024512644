import {
  Stack,
  Paper,
  Typography,
  InputBase,
  InputAdornment,
  IconButton,
  alpha,
} from "@mui/material";
import Iconify from "../../components/iconify";
import { fDateTime } from "../../utils/formatTime";
import { useCallback, useEffect, useState } from "react";
import Scrollbar from "../../components/scrollbar/Scrollbar";
import OrderAttachments from "./OrderAttachments";
import { Upload } from "../../components/upload";
import {
  addTimelineActivity,
  getDealActivities,
} from "../../api/bitrix/activity";
import { useSharedContext } from "../SharedComponentsContext";
import { ConfigurableActivity } from "../../api/bitrix/types/activity";
import AWS from "aws-sdk";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let BX24: any;
type PlacementInfo = {
  options: {
    ID: string;
  };
};

export const uploadFileAndGetLocation = (
  file: File,
  s3: AWS.S3
): Promise<string> =>
  new Promise((resolve, reject) => {
    const randomDigits = Math.random().toString().slice(2, 12); // Generates 10 random digits
    const timestamp = Date.now(); // Get the current timestamp

    const params = {
      Bucket: process.env.REACT_APP_AWS_S3_NAME as string,
      Key: randomDigits + timestamp + "_" + file.name,
      Body: file,
      ContentType: file.type,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    s3.upload(params, (err: any, data: { Location: string }) => {
      if (err) {
        reject(err);
      } else {
        resolve(data.Location);
      }
    });
  });

interface OrderMessagesProps {
  variant: "client" | "parnerus";
  handleAddFiles?: (fileUris: string[]) => void;
}

export default function OrderMessages({
  variant,
  handleAddFiles,
}: OrderMessagesProps) {
  const { deal } = useSharedContext();
  const [dealId, setDealId] = useState<string>();
  const [newMessage, setNewMessage] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<
    {
      data: ConfigurableActivity["layout"];
      createdDate: Date;
    }[]
  >();
  const [files, setFiles] = useState<File[]>([]);
  const [showFilesUploader, setShowFilesUploader] = useState(false);

  const handleDropMultiFile = useCallback(
    (acceptedFiles: File[]) => {
      setFiles([
        ...files,
        ...acceptedFiles.map((newFile) =>
          Object.assign(newFile, {
            preview: URL.createObjectURL(newFile),
          })
        ),
      ]);
    },
    [files]
  );

  const handleRemoveFile = (inputFile: File | string) => {
    const filesFiltered = files.filter(
      (fileFiltered) => fileFiltered !== inputFile
    );
    setFiles(filesFiltered);
  };

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  const handleSendActivity = async () => {
    if (dealId && newMessage && messages) {
      setLoading(true);
      const filesUrls = [];
      if (files) {
        AWS.config.update({
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID_S3,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY_S3,
          region: "us-east-1",
        });

        const s3 = new AWS.S3();

        for (const newFile of files) {
          filesUrls.push({
            name: newFile.name,
            uri: await uploadFileAndGetLocation(newFile, s3),
          });
        }
      }

      setNewMessage("");
      setFiles([]);
      const newActivityLayout = await addTimelineActivity(
        Number(dealId),
        2,
        deal ? Number(deal.ASSIGNED_BY_ID) : 1,
        newMessage,
        filesUrls,
        variant,
        handleAddFiles
      );
      setMessages([
        { data: newActivityLayout, createdDate: new Date() },
        ...messages,
      ]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dealId) {
      getDealActivities(dealId).then(setMessages);
    }
  }, [dealId]);

  useEffect(() => {
    if (deal) {
      setDealId(deal.ID);
    } else {
      try {
        // eslint-disable-next-line no-undef
        const { options } = BX24.placement.info() as PlacementInfo;
        setDealId(options.ID);
      } catch {
        // setDealId("195"); // For demo purposes
        console.log("no placement found.");
      }
    }
  }, [deal]);

  return (
    <>
      <Scrollbar sx={{ height: 400 }}>
        <Stack spacing={1.5} sx={{ pb: 2 }}>
          {messages?.map((msg, index) => (
            <Stack key={index} direction="row" spacing={2}>
              {/* key={msg.messageId} */}
              <Paper
                sx={{
                  p: 1.5,
                  flexGrow: 1,
                  bgcolor: msg.data.header.title.en.includes("Parnerus")
                    ? "#D9E3FF"
                    : "background.neutral",
                }}
              >
                <Stack
                  justifyContent="space-between"
                  direction={{ xs: "column", sm: "row" }}
                  alignItems={{ sm: "center" }}
                  sx={{ mb: 0.5 }}
                >
                  <Typography variant="subtitle2">
                    {msg.data.header.title.en.includes("Parnerus")
                      ? "Mensaje de Parnerus"
                      : "Mensaje enviado"}
                  </Typography>

                  <Typography variant="caption" sx={{ color: "text.disabled" }}>
                    {fDateTime(msg.createdDate)}
                  </Typography>
                </Stack>

                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {
                    msg.data.body.blocks.find((b) => b.type === "largeText")
                      ?.properties.value
                  }
                </Typography>
                <Stack direction="row">
                  {/* {orderFiles && orderFiles.length ? ( */}
                  <OrderAttachments
                    attachments={msg.data.body.blocks.reduce(
                      (prev: string[], curr) =>
                        curr.type === "link"
                          ? [...prev, curr.properties.action?.uri as string]
                          : prev,
                      []
                    )}
                    iconSize={32}
                  />
                  {/* ) : (
                      <Typography variant="caption">
                        No hay archivos en esta orden.
                      </Typography>
                    )} */}
                </Stack>
              </Paper>
            </Stack>
          ))}
        </Stack>
      </Scrollbar>

      <Stack spacing={2} direction="row" alignItems="center">
        <InputBase
          fullWidth
          value={newMessage}
          // inputRef={commentInputRef}
          placeholder="Escribe un mensaje…"
          disabled={loading}
          onChange={(event) => setNewMessage(event.target.value as string)}
          endAdornment={
            <>
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => setShowFilesUploader(!showFilesUploader)}
                  disabled={loading}
                >
                  <Iconify icon="eva:attach-2-fill" />
                </IconButton>
              </InputAdornment>
              <InputAdornment position="end" sx={{ mr: 1 }}>
                <IconButton
                  size="small"
                  onClick={handleSendActivity}
                  disabled={loading}
                >
                  {/* onClick={handleClickAttach} */}
                  <Iconify icon="ic:baseline-send" />
                </IconButton>
              </InputAdornment>
            </>
          }
          sx={{
            pl: 1.5,
            height: 40,
            borderRadius: 1,
            border: (theme) =>
              `solid 1px ${alpha(theme.palette.grey[500], 0.32)}`,
          }}
        />

        <input type="file" style={{ display: "none" }} />
        {/* ref={fileInputRef} */}
      </Stack>

      {showFilesUploader && (
        <Upload
          multiple
          files={files}
          onDrop={handleDropMultiFile}
          onRemove={handleRemoveFile}
          onRemoveAll={handleRemoveAllFiles}
          onUpload={() => console.log("ON UPLOAD")}
          disabled={loading}
        />
      )}
    </>
  );
}
