import { useState } from "react";
// @mui
import {
  Stack,
  Button,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
  Typography,
} from "@mui/material";
// components
import Iconify from "../../../components/iconify";
import MenuPopover from "../../../components/menu-popover";
import ConfirmDialog from "../../../components/confirm-dialog";
import { useSharedContext } from "../../../shared/SharedComponentsContext";
import { dbCompany } from "../../../api/parnerus/types/company";
import { useBitrixContext } from "../../../api/bitrix/context/BitrixContext";
import Label from "../../../components/label";
import { CustomAvatar } from "../../../components/custom-avatar";

// ----------------------------------------------------------------------

type Props = {
  row: dbCompany;
  selected?: boolean;
  onEditRow?: VoidFunction;
  onSelectRow?: VoidFunction;
  onDeleteRow?: VoidFunction;
};

export default function CompaniesTableRow({
  row,
  selected,
  onDeleteRow,
}: Props) {
  const { openCompanyViewSlider, openCompanyEditSlider } = useSharedContext();
  const { userfieldList } = useBitrixContext();
  const {
    TITLE,
    UF_STATUS,
    UF_IRS_STATUS,
    UF_STATE_OF_INCORPORATION,
    primaryContactId,
    contact,
  } = row;

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const status = userfieldList
    .find((u) => u.FIELD_NAME === "UF_CRM_1683977873")
    ?.LIST.find((v) => v.ID === UF_STATUS)?.VALUE;

  const irsStatus = userfieldList
    .find((u) => u.FIELD_NAME === "UF_CRM_1683978024")
    ?.LIST.find((v) => v.ID === UF_IRS_STATUS)?.VALUE;

  const stateOfIncorporation = userfieldList
    .find((u) => u.FIELD_NAME === "UF_CRM_1683982286")
    ?.LIST.find((v) => v.ID === UF_STATE_OF_INCORPORATION)?.VALUE;

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell onClick={() => openCompanyViewSlider(row)}>
          <Stack direction="row" alignItems="center" spacing={2}>
            {/* <CustomAvatar name={TITLE} /> */}

            <Typography variant="subtitle2" noWrap>
              {TITLE}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="left" onClick={() => openCompanyViewSlider(row)}>
          {status && (
            <Label
              variant="soft"
              color={(UF_STATUS === "67" && "error") || "success"}
              sx={{ textTransform: "capitalize" }}
            >
              {status}
            </Label>
          )}
        </TableCell>

        <TableCell align="left" onClick={() => openCompanyViewSlider(row)}>
          {irsStatus && (
            <Label
              variant="soft"
              color={(UF_IRS_STATUS === "69" && "error") || "success"}
              sx={{ textTransform: "capitalize" }}
            >
              {irsStatus}
            </Label>
          )}
        </TableCell>

        <TableCell onClick={() => openCompanyViewSlider(row)}>
          <Stack direction="row" alignItems="center" spacing={2}>
            {primaryContactId && contact ? (
              <>
                <CustomAvatar name={contact.NAME} />

                <Typography variant="subtitle2" noWrap>
                  {contact.NAME} {contact.LAST_NAME}
                </Typography>
              </>
            ) : (
              "-"
            )}
          </Stack>
        </TableCell>

        <TableCell align="left" onClick={() => openCompanyViewSlider(row)}>
          {stateOfIncorporation && (
            <Label
              variant="soft"
              color="success"
              sx={{ textTransform: "capitalize" }}
            >
              {stateOfIncorporation}
            </Label>
          )}
        </TableCell>

        <TableCell align="right">
          <IconButton
            color={openPopover ? "inherit" : "default"}
            onClick={handleOpenPopover}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            handleClosePopover();
            openCompanyViewSlider(row);
          }}
        >
          <Iconify icon="clarity:details-line" />
          Detalles
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClosePopover();
            openCompanyEditSlider(row);
          }}
        >
          <Iconify icon="eva:edit-fill" />
          Editar
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: "error.main" }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Eliminar
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Eliminar"
        content="¿Estás seguro que deseas eliminar este registro? Esto deshabilitará el registro, y sólo quedará visible para los registros que estén relacionado al mismo, pero no podrás encontrarlo más en este listado"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Eliminar
          </Button>
        }
      />
    </>
  );
}
