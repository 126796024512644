// import "./ExternalMessagePage.css";
import { Card, Grid } from "@mui/material";
import OrderMessages from "../../shared/OrderViewSlider/OrderMessages";

export default function ExternalMessagePage() {
  return (
    <Grid
      container
      justifyContent="center"
      p={6}
      sx={{
        height: "100%",
        backgroundImage: "url(/assets/background/background-parnerus.png)",
        backgroundSize: "70%",
      }}
    >
      <Grid item>
        <Card sx={{ padding: 10, width: 600 }}>
          <OrderMessages variant="parnerus" />
        </Card>
      </Grid>
    </Grid>
  );
}
